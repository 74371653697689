import React from 'react'
import { renderRichText } from "gatsby-source-contentful/rich-text";
import styled from 'styled-components';
import { H5Style } from './Styles/typography';

const StyldRichText = styled.div`
  ${H5Style};

  a {
    color: inherit;
  }

  b {
    font-weight: 600;
  }

  i {
    font-style: italic;
  }

  u {
    text-decoration: underline;
  }

  p {
    margin-bottom: 20px;
    :last-of-type {
      margin-bottom: 0;
    }
  }

  ul {
    list-style: disc;

    li {
      margin-left: 20px;
      margin-bottom: 10px;

      :last-of-type {
        margin-bottom: 0;
      }

      p {
        :last-of-type {
          margin-bottom : 0;
        }
      }

    }
  }
`

const options = {
  renderText: (text) => {
    return text.split("\n").reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
  },
};

const RichText = ({content}) => {
  return (
    <StyldRichText>
      {renderRichText(content, options)}
    </StyldRichText>
  )
}

export default RichText
